@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

:root {
  --green: #d6ffab;
  --green-dark: #363d2b;
  --green-dark-rgb: 54, 61, 43;
  --off-white: #fff7f2;
  --off-white-rgb: 255, 247, 242;
  --main-pink-color: #ffd5d1;
  --main-blue-color: #d9dcff;
  --dark-red: #f44336;
  --light-red: #ffeae8;
  --purple: #6369bd;
  --pink-dark: #f6c0bb;
  --yellow: #ffedab;
  --main-text-color: var(--green-dark);
  --main-bg-color: var(--off-white);
  --light-border-color: rgba(36, 35, 35, 0.87);
  --green-dark-faded: rgba(54, 61, 43, 0.6);

  --sidebar-width: 250px;
  --header-height: 64px;

  --font: 'Nunito Sans', sans-serif;

  --fw-regular: 400;
  --fw-semibold: 600;
  --fw-bold: 700;

  --zi-header: 10;
  --zi-sync-bar: 15;
  --zi-loader: 20;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-family: var(--font);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

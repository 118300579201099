.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.leftContainer {
  align-items: center;
  display: flex;
  text-align: center;
}

.downloadApp {
  /* padding: 10%; */
  position: absolute;
  max-width: 360px;
  left: 40px;
  bottom: 40px;
  z-index: 1;
}

.collage {
  position: absolute;
  bottom: 0;
  right: 0;
}
.signInButton {
  color: black;
}
.loginSection {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.intro {
  font-weight: var(--fw-semibold);
  font-size: 14px;
  width: 100%;
}

.title {
  margin: 0;
  margin-bottom: 24px;
}

.title img {
  display: block;
  width: 80px;
}

.form {
  margin-top: 24px;
}

.formControl {
  width: 320px;
}

.formControl + .formControl {
  margin-top: 24px;
}

.formButtonWrapper {
  margin-top: 48px;
}

.formButtonLink {
  margin-top: 16px;
}

.formInput {
  width: 100%;
}

.socialLoginButtons {
  margin-top: 32px;
}

.socialLoginButton + .socialLoginButton {
  margin-left: 16px !important;
}

.socialLoginButton img {
  display: block;
  width: 16px;
  margin-right: 8px;
}

.downloadTitle {
  font-weight: var(--fw-bold);
  font-size: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.downloadText {
  font-size: 14px;
}

.btnDownload {
  margin-top: 16px !important;
}

.generalError {
  margin-top: 24px !important;
  text-align: center !important;
  max-width: 240px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.successMessage {
  color: #6369bd !important;
  margin-top: 24px !important;
  text-align: center !important;
}

.blobImage {
  position: absolute;
  width: 88px;
  left: 0;
  bottom: 0;
}

.dialogForm {
  margin-top: 16px;
}

@media (max-width: 1199px) {
  .collage {
    width: 200px;
  }
}

@media (max-width: 1023px) {
  .mainContainer {
    /* flex-direction: column; */
    /* display: block; */
  }
  .mainContainer {
    height: auto;
  }

  .leftContainer {
    width: 100%;
  }

  .intro {
    width: auto;
  }

  .collage {
    width: 100px;
  }

  .loginSection {
    width: 100%;
    padding: 48px 24px;
  }

  .downloadApp {
    padding: 48px 24px;
    position: static;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .collage {
    position: static;
    align-self: flex-end;
  }

  .blobImage {
    display: none;
  }
}

.backdrop {
  background-color: rgba(var(--off-white-rgb), 0.8) !important;
  z-index: var(--zi-loader) !important;
  flex-direction: column;
}

@media (max-width: 767px) {
  .loginSection {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .formControl {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .socialLoginButtons {
    display: flex;
    flex-direction: column;
  }
  .socialLoginButton {
    /* flex-direction: row; */
    display: flex !important;
  }

  .socialLoginButton + .socialLoginButton {
    margin-left: 0 !important;
    margin-top: 24px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
:root {
  --green: #d6ffab;
  --green-dark: #363d2b;
  --green-dark-rgb: 54, 61, 43;
  --off-white: #fff7f2;
  --off-white-rgb: 255, 247, 242;
  --main-pink-color: #ffd5d1;
  --main-blue-color: #d9dcff;
  --dark-red: #f44336;
  --light-red: #ffeae8;
  --purple: #6369bd;
  --pink-dark: #f6c0bb;
  --yellow: #ffedab;
  --main-text-color: var(--green-dark);
  --main-bg-color: var(--off-white);
  --light-border-color: rgba(36, 35, 35, 0.87);
  --green-dark-faded: rgba(54, 61, 43, 0.6);

  --sidebar-width: 250px;
  --header-height: 64px;

  --font: 'Nunito Sans', sans-serif;

  --fw-regular: 400;
  --fw-semibold: 600;
  --fw-bold: 700;

  --zi-header: 10;
  --zi-sync-bar: 15;
  --zi-loader: 20;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #fff7f2;
  background-color: var(--main-bg-color);
  color: #363d2b;
  color: var(--main-text-color);
  font-family: 'Nunito Sans', sans-serif;
  font-family: var(--font);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.landing_mainContainer__2PEez {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.landing_leftContainer__3D-NW {
  align-items: center;
  display: flex;
  text-align: center;
}

.landing_downloadApp__1EYiC {
  /* padding: 10%; */
  position: absolute;
  max-width: 360px;
  left: 40px;
  bottom: 40px;
  z-index: 1;
}

.landing_collage__2oKpi {
  position: absolute;
  bottom: 0;
  right: 0;
}
.landing_signInButton__22Zgh {
  color: black;
}
.landing_loginSection__b8BQg {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.landing_intro__1A7tG {
  font-weight: var(--fw-semibold);
  font-size: 14px;
  width: 100%;
}

.landing_title__kqdx7 {
  margin: 0;
  margin-bottom: 24px;
}

.landing_title__kqdx7 img {
  display: block;
  width: 80px;
}

.landing_form__DUKFI {
  margin-top: 24px;
}

.landing_formControl__3p0da {
  width: 320px;
}

.landing_formControl__3p0da + .landing_formControl__3p0da {
  margin-top: 24px;
}

.landing_formButtonWrapper__1jftB {
  margin-top: 48px;
}

.landing_formButtonLink__35Gui {
  margin-top: 16px;
}

.landing_formInput__2AUla {
  width: 100%;
}

.landing_socialLoginButtons__37UAk {
  margin-top: 32px;
}

.landing_socialLoginButton__M-LY6 + .landing_socialLoginButton__M-LY6 {
  margin-left: 16px !important;
}

.landing_socialLoginButton__M-LY6 img {
  display: block;
  width: 16px;
  margin-right: 8px;
}

.landing_downloadTitle__e3OUy {
  font-weight: var(--fw-bold);
  font-size: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.landing_downloadText__3ipjg {
  font-size: 14px;
}

.landing_btnDownload__3AoUp {
  margin-top: 16px !important;
}

.landing_generalError__27xRa {
  margin-top: 24px !important;
  text-align: center !important;
  max-width: 240px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.landing_successMessage__3gmhf {
  color: #6369bd !important;
  margin-top: 24px !important;
  text-align: center !important;
}

.landing_blobImage__3Ovoq {
  position: absolute;
  width: 88px;
  left: 0;
  bottom: 0;
}

.landing_dialogForm__1gcRF {
  margin-top: 16px;
}

@media (max-width: 1199px) {
  .landing_collage__2oKpi {
    width: 200px;
  }
}

@media (max-width: 1023px) {
  .landing_mainContainer__2PEez {
    /* flex-direction: column; */
    /* display: block; */
  }
  .landing_mainContainer__2PEez {
    height: auto;
  }

  .landing_leftContainer__3D-NW {
    width: 100%;
  }

  .landing_intro__1A7tG {
    width: auto;
  }

  .landing_collage__2oKpi {
    width: 100px;
  }

  .landing_loginSection__b8BQg {
    width: 100%;
    padding: 48px 24px;
  }

  .landing_downloadApp__1EYiC {
    padding: 48px 24px;
    position: static;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .landing_collage__2oKpi {
    position: static;
    align-self: flex-end;
  }

  .landing_blobImage__3Ovoq {
    display: none;
  }
}

.landing_backdrop__1Mogp {
  background-color: rgba(var(--off-white-rgb), 0.8) !important;
  z-index: var(--zi-loader) !important;
  flex-direction: column;
}

@media (max-width: 767px) {
  .landing_loginSection__b8BQg {
    width: 100%;
  }

  .landing_form__DUKFI {
    width: 100%;
  }

  .landing_formControl__3p0da {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .landing_socialLoginButtons__37UAk {
    display: flex;
    flex-direction: column;
  }
  .landing_socialLoginButton__M-LY6 {
    /* flex-direction: row; */
    display: flex !important;
  }

  .landing_socialLoginButton__M-LY6 + .landing_socialLoginButton__M-LY6 {
    margin-left: 0 !important;
    margin-top: 24px;
  }
}

